export class ContactForm {
  name = '';
  email = '';
  phone = '';
  subject = '';
  message = '';
  language = '';
  allowMarketing = false;
  recaptchaToken = '';
}
