
import { Component, Mixins, Watch } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';
import { ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import env from '../../env.config';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import FormInput from '../components/FormInput.vue';
import MezureContact from '@/components/MezureContact.vue';
import FooterContactInfo from '../components-smart/FooterContactInfo.vue';
import ContactDirections from '../components/ContactDirections.vue';
import { ContactForm } from '../models/contact-form/contact-form.class';
import { SelectOption } from '../models/shared/select-option.interface';
import { ContactOption } from '../enums/contact-option.enum';
import { translateApi } from '../utils/translate-api';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Contact as ContactModel } from '../models/static-contents/contact.interface';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import StaffMember from '@/models/staff-members/staff-member.class';

const staticContentsModule = namespace('staticContents');
const appModule = namespace('app');
const staffModule = namespace('staffMembers');

@Component({
  components: {
    ValidationObserver,
    FormInput,
    FooterContactInfo,
    VueRecaptcha,
    ContactDirections,
    MezureContact,
  },
  filters: {
    translateApi,
  },
})
export default class Contact extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @appModule.Action('submitContactForm')
  submitContactForm!: (form: ContactForm) => Promise<ContactForm>;

  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => ContactModel;

  @staffModule.Action('fetchUnpaginated')
  fetchStaffMembers!: () => StaffMember[];

  @staffModule.Getter('all')
  staffMembers!: StaffMember[];

  contactContent: ContactModel | null = null;

  contactForm: ContactForm = new ContactForm();

  mezureContact: StaffMember | null = null;

  submitBtnEnabled = false;
  isLoading = false;
  successMessageSeconds = 0;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
    recaptcha: InstanceType<typeof VueRecaptcha>;
  };

  @Watch('$route.params', { immediate: true, deep: true })
  onRouteParamsChanged() {
    this.contactForm.subject = this.$route.params?.subject || this.contactForm.subject;
    this.contactForm.message = this.$route.params?.courseTitle || this.contactForm.message;
  }

  get headerHtml() {
    return `<h2 class="text-white">${this.$t('navbar.contact')}</h2>`;
  }

  get subjectOptions(): SelectOption[] {
    return Object.values(ContactOption).map(option => {
      return {
        text: this.$t(`contact.options.${option}`) as string,
        value: option,
      };
    });
  }

  get googleRecaptchaSiteKey() {
    return env.RECAPTCHA_SITE_KEY;
  }

  async onReCaptchaVerify(token: string) {
    if (token) {
      this.contactForm.recaptchaToken = token;
      this.submitBtnEnabled = true;
    }
  }

  onReCaptchaExpired() {
    this.submitBtnEnabled = false;
  }

  async onSubmitForm() {
    this.isLoading = true;

    this.contactForm.language = this.localeCode;
    const contactResponse = await this.submitContactForm(this.contactForm);

    if (contactResponse) {
      this.resetContactForm();
      this.successMessageSeconds = 8;
      window.scrollTo(0, 0);
    }

    this.isLoading = false;
  }

  resetContactForm() {
    this.contactForm = new ContactForm();
    this.$refs.observer.reset();
    this.$refs.recaptcha.reset();
    this.submitBtnEnabled = false;
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Contact)) {
      await this.fetchStaticContent(StaticContentType.Contact);
    }
    this.contactContent = this.getStaticContentByType(StaticContentType.Contact);
    await this.fetchStaffMembers();

    this.mezureContact =
      this.staffMembers.find(memb => memb.staffVisiblePages.includes('Contactpage')) || null;
  }
}
